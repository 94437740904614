import React from "react";
import { TagForm } from "../features/tags/TagForm";
import { ListPage } from "../features/components/ListPage";
import { useGetInsertsQuery, useGetTagsQuery } from "../features/api/apiSlice";
import { InsertableAudio, InsertableAudioId, Tag } from "../features/api/interfaces";
import { ArrowRight, ArrowUpRight } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";

function getInsertName(insert_id: InsertableAudioId, inserts: InsertableAudio[]) {
  const insert = inserts.find(
    (value: InsertableAudio, index: number, obj: InsertableAudio[]) => value.id === insert_id
  );
  if (insert) {
    return insert.name;
  }
  return "";
}

interface TagListItemProps {
  obj: Tag;
  meta: { inserts: InsertableAudio[] };
}

function TagListItemSummary(props: TagListItemProps) {
  return (
    <Row style={{ height: "100%" }} className="align-items-center">
      <Col xs="auto">
        <ArrowRight />
      </Col>
      <Col>{getInsertName(props.obj.insertable_audio_id, props.meta.inserts)}</Col>
    </Row>
  );
}

function TagListItemDetail(props: TagListItemProps) {
  return (
    <React.Fragment>
      ID: {props.obj.id}
      <br />
      Name: {props.obj.name}
      <br />
      Insert Name: {getInsertName(props.obj.insertable_audio_id, props.meta.inserts)}
      <br />
      Insert ID: {props.obj.insertable_audio_id}
    </React.Fragment>
  );
}

function Tags() {
  const insertsRes = useGetInsertsQuery();
  const tagsRes = useGetTagsQuery();

  if (insertsRes.isLoading) {
    //content = <Spinner text="Loading..." />
  } else if (insertsRes.isSuccess) {
    // content = res.data.map((tag: Tag) => <ListGroup.Item key={tag.id}>{tag.name}</ListGroup.Item>)
  } else if (insertsRes.isError && insertsRes.error) {
    // content = <div>{res.error.toString()}</div>
  }

  if (tagsRes.isLoading) {
    //content = <Spinner text="Loading..." />
  } else if (tagsRes.isSuccess) {
    // content = res.data.map((tag: Tag) => <ListGroup.Item key={tag.id}>{tag.name}</ListGroup.Item>)
  } else if (tagsRes.isError && tagsRes.error) {
    // content = <div>{res.error.toString()}</div>
  }

  if (insertsRes.isSuccess && tagsRes.isSuccess) {
    return (
      <ListPage
        id="tags"
        objName="Tag"
        objNamePlural="Tags"
        objs={tagsRes.data}
        meta={{ inserts: insertsRes.data }}
        AddObjForm={TagForm}
        EditObjForm={TagForm}
        ObjSummaryComponent={TagListItemSummary}
        ObjDetailComponent={TagListItemDetail}
      />
    );
  }
  return <React.Fragment></React.Fragment>;
}

export default Tags;
