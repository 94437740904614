import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { InsertableAudio } from "../api/interfaces";
import { cloneDeep } from "lodash";
import {
  useCreateInsertMutation,
  useDeleteInsertMutation,
  useEditInsertMutation,
  useUploadInsertAudioMutation,
} from "../api/apiSlice";
import { CreateEditDeleteForm } from "../components/CreateEditDeleteForm";

interface InsertFormProps {
  obj?: InsertableAudio;
  onClose: () => void;
}

export function InsertForm(props: InsertFormProps) {
  const [name, setName] = useState(props.obj ? props.obj.name : "");
  const [file, setFile] = useState<File>();

  const [createInsert, createRes] = useCreateInsertMutation();
  const [editInsert, editRes] = useEditInsertMutation();
  const [deleteInsert, deleteRes] = useDeleteInsertMutation();
  const [uploadAudio, uploadRes] = useUploadInsertAudioMutation();

  const isFormValid = [name, file || props.obj].every(Boolean);

  async function onSaveClicked() {
    if (isFormValid) {
      try {
        if (props.obj) {
          // edit
          const copy: InsertableAudio = cloneDeep(props.obj);
          copy.name = name;
          // TODO error handling
          await editInsert(copy).unwrap();
          if (file) {
            await uploadAudio({ insert_id: copy.id, audio: file }).unwrap();
          }
        } else {
          // create
          // TODO error handling
          const payload = await createInsert({ name: name }).unwrap();
          if (payload && file) {
            await uploadAudio({ insert_id: payload.id, audio: file }).unwrap();
          }
        }
      } catch (err) {
        console.error("Failed to save: ", err);
      }
      props.onClose();
    }
  }

  async function onDeleteClicked() {
    if (!props.obj) {
      return;
    }
    try {
      await deleteInsert(props.obj.id).unwrap();
    } catch (err) {
      console.error("Failed to delete the Insert: ", err);
    }
    props.onClose();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNameChanged = (e: any) => setName(e.target.value);

  // https://bobbyhadz.com/blog/aws-s3-presigned-url-react
  // https://github.com/bobbyhadz/aws-s3-presigned-urls-react/blob/cdk-v2/src/pages/index.page.tsx
  function onFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log("fileObj is", fileObj);
    setFile(fileObj);
  }

  const controls = [
    {
      key: "name",
      label: <Form.Label htmlFor="insertName">Name</Form.Label>,
      control: <Form.Control type="text" id="insertName" name="insertName" value={name} onChange={onNameChanged} />,
    },
  ];
  controls.push({
    key: "file",
    label: <Form.Label>Audio File</Form.Label>,
    control: (
      <Form.Control
        type="file"
        // accept="audio/mpeg,audio/mp3"
        accept="audio/wav"
        onChange={onFileChanged}
      />
    ),
  });

  return (
    <CreateEditDeleteForm
      showDeleteButton={Boolean(props.obj)}
      showSaveSpinner={createRes.isLoading || editRes.isLoading || uploadRes.isLoading}
      showDeleteSpinner={deleteRes.isLoading}
      canSave={isFormValid}
      onCancel={() => props.onClose()}
      onSave={() => onSaveClicked()}
      onDelete={() => onDeleteClicked()}
      controls={controls}
      deleteConfirmText="Deleting an Insert will also remove associated Tags, and remove it from Episodes where it is used. Those Episodes will be re-generated."
    />
  );
}
