import React from "react";
import { Navbar } from "react-bootstrap";
import { Flower1 } from "react-bootstrap-icons";

function NavBarLogo() {
  return (
    <React.Fragment>
      <Navbar.Brand href="#">
        <Flower1 size="2em" className="logo d-inline-block align-middle" />
      </Navbar.Brand>
    </React.Fragment>
  );
}

export default NavBarLogo;
