import React, { ComponentType } from "react";
import Landing from "./views/Landing";
import App from "./views/App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Episodes from "./views/Episodes";
import Inserts from "./views/Inserts";
import Podcasts from "./views/Podcasts";
import Tags from "./views/Tags";
import UserProfile from "./views/UserProfile";
import { withAuthenticationRequired } from "@auth0/auth0-react";

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div className="page-layout">Auth....</div>,
  });

  return <Component />;
};

function Home() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/app" element={<ProtectedRoute component={App} />}>
          <Route path="episodes" element={<ProtectedRoute component={Episodes} />} />
          <Route path="inserts" element={<ProtectedRoute component={Inserts} />} />
          <Route path="tags" element={<ProtectedRoute component={Tags} />} />
          <Route path="podcasts" element={<ProtectedRoute component={Podcasts} />} />
          <Route path="settings" element={<ProtectedRoute component={UserProfile} />} />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Home;
