import { Duration } from "luxon";

type MyWindow = Window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SERVER_DATA: any
}

export function getEnv(key: string): string {
  const w = window as unknown as MyWindow;
  if (w.SERVER_DATA !== undefined && w.SERVER_DATA[key] !== undefined) {
    return w.SERVER_DATA[key] as string;
  }
  if (process.env[key] !== undefined) {
    return process.env[key] as string;
  }
  return "";
}

// format duration as <minutes>:<seconds always as two digits>.<milliseconds trimmed to precision always as n digits>
export function formatDuration(value: Duration, precision: number): string {
  const obj = value.shiftTo("minutes", "seconds", "milliseconds").toObject();
  let milliseconds = obj["milliseconds"];
  if (milliseconds === undefined) {
    milliseconds = 0;
  }
  // trim trailing digits to desired precision
  milliseconds = milliseconds / precision;
  return `${value.toFormat("m:ss")}.${milliseconds}`;
}
