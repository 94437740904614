import React, { useState } from "react";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";
import { useCreateTagMutation, useDeleteTagMutation, useEditTagMutation } from "../api/apiSlice";
import { InsertableAudio, InsertableAudioId, Tag } from "../api/interfaces";
import { cloneDeep } from "lodash";
import { CreateEditDeleteForm } from "../components/CreateEditDeleteForm";

interface TagFormProps {
  obj?: Tag;
  meta: { inserts: InsertableAudio[] };
  onClose: () => void;
}

export function TagForm(props: TagFormProps) {
  const [name, setName] = useState(props.obj ? props.obj.name : "");
  const [insert, setInsert] = useState(() => {
    if (!props.obj) {
      return undefined;
    } else {
      return props.meta.inserts.find(
        (value: InsertableAudio, index: number, obj: InsertableAudio[]) =>
          props.obj && value.id === props.obj.insertable_audio_id
      );
    }
  });

  const [createTag, createRes] = useCreateTagMutation();
  const [editTag, editRes] = useEditTagMutation();
  const [deleteTag, deleteRes] = useDeleteTagMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNameChanged = (e: any) => setName(e.target.value);
  const isFormValid = [name, insert].every(Boolean);

  async function onSaveClicked() {
    if (isFormValid && insert) {
      try {
        if (props.obj) {
          // edit
          const copy: Tag = cloneDeep(props.obj);
          copy.name = name;
          copy.insertable_audio_id = insert.id;
          await editTag(copy).unwrap();
        } else {
          // create
          await createTag({ name: name, insertable_audio_id: insert.id }).unwrap();
        }
      } catch (err) {
        console.error("Failed to save: ", err);
      }
      props.onClose();
    }
  }

  async function onDeleteClicked() {
    if (!props.obj) {
      return;
    }
    try {
      await deleteTag(props.obj.id).unwrap();
    } catch (err) {
      console.error("Failed to save the Tag: ", err);
    }
    props.onClose();
  }

  function onSelectInsert(eventKey: string | null): void {
    if (!eventKey) {
      setInsert(undefined);
    }
    const insert = props.meta.inserts.find(
      (value: InsertableAudio, index: number, obj: InsertableAudio[]) => value.id === (eventKey as InsertableAudioId)
    );
    if (!insert) {
      setInsert(undefined);
    }
    setInsert(cloneDeep(insert));
  }

  const inserts: JSX.Element[] = props.meta.inserts.map((insert: InsertableAudio, index: number) => (
    <Dropdown.Item key={insert.id} eventKey={insert.id as string}>
      {insert.name}
    </Dropdown.Item>
  ));

  const controls = [
    {
      key: "name",
      label: <Form.Label htmlFor="tagName">Name:</Form.Label>,
      control: <Form.Control type="text" id="tagName" name="tagName" value={name} onChange={onNameChanged} />,
    },
    {
      key: "insert",
      label: <Form.Label>Insert</Form.Label>,
      control: (
        <DropdownButton
          title={insert ? insert.name : "Select Insert"}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSelect={(eventKey: string | null, event: any) => onSelectInsert(eventKey)}
        >
          {inserts}
        </DropdownButton>
      ),
    },
  ];

  return (
    <CreateEditDeleteForm
      showDeleteButton={Boolean(props.obj)}
      showSaveSpinner={createRes.isLoading || editRes.isLoading}
      showDeleteSpinner={deleteRes.isLoading}
      canSave={isFormValid}
      onCancel={() => props.onClose()}
      onSave={() => onSaveClicked()}
      onDelete={() => onDeleteClicked()}
      controls={controls}
      deleteConfirmText="Deleting a Tag will also remove it from all Episodes where it is used, and those Episodes will be re-generated."
    />
  );
}
