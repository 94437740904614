import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";
import { ButtonProps } from "react-bootstrap";

const LoginButton = (props: { children?: React.ReactNode } & ButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button onClick={() => loginWithRedirect()} variant="primary" {...props}>
      { props.children ? props.children : 'Log In | Create Account'}
    </Button>
  );
};

export default LoginButton;
