import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import LogoutButton from "../features/components/LogoutButton";
import NavBarLogo from "../features/components/NavBarLogo";

function App() {
  return (
    <div className="App">
      <Navbar bg="primary" variant="dark" expand="md">
        <Container>
          <NavBarLogo />
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="order-first" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="episodes">
                Episodes
              </Nav.Link>
              <Nav.Link as={Link} to="inserts">
                Inserts
              </Nav.Link>
              <Nav.Link as={Link} to="tags">
                Tags
              </Nav.Link>
              <Nav.Link as={Link} to="podcasts">
                Podcasts
              </Nav.Link>
            </Nav>
            <Nav className="justify-content-end">
              <Nav.Link as={Link} to="settings">
                Settings
              </Nav.Link>
              <Nav.Link as={LogoutButton}></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div id="app-outlet">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
