import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useCreateEpisodeMutation, useUploadEpisodeAudioMutation } from "../api/apiSlice";
import { Episode, SerialId } from "../api/interfaces";
import { CreateEditDeleteForm } from "../components/CreateEditDeleteForm";

interface AddEpisodeFromProps {
  meta?: { serial: SerialId | undefined };
  onClose: () => void;
}

export function AddEpisodeForm(props: AddEpisodeFromProps) {
  const [name, setName] = useState("");
  const [file, setFile] = useState<File>();

  const [createEpisode, createRes] = useCreateEpisodeMutation();
  const [uploadAudio, uploadRes] = useUploadEpisodeAudioMutation();

  const isFormValid = [name, file].every(Boolean);

  const onSaveClicked = async () => {
    console.log("AddEpisodeForm/opnSaveClicked");
    console.log(JSON.stringify(props));
    const canSave = isFormValid && !createRes.isLoading && !uploadRes.isLoading;

    if (canSave && props.meta && props.meta.serial) {
      try {
        const payload: Episode = await createEpisode({ name: name, serial_id: props.meta.serial }).unwrap();
        console.log(JSON.stringify(payload));
        if (file) {
          await uploadAudio({ episode_id: payload.id, audio: file }).unwrap();
        }
      } catch (err) {
        console.error("Failed to save the post: ", err);
      }
      props.onClose();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNameChanged = (e: any) => setName(e.target.value);

  // https://bobbyhadz.com/blog/aws-s3-presigned-url-react
  // https://github.com/bobbyhadz/aws-s3-presigned-urls-react/blob/cdk-v2/src/pages/index.page.tsx
  function onFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log("fileObj is", fileObj);
    setFile(fileObj);
  }

  const controls = [
    {
      key: "name",
      label: <Form.Label htmlFor="episodeName">Episode Name:</Form.Label>,
      control: <Form.Control type="text" id="episodeName" name="episodeName" value={name} onChange={onNameChanged} />,
    },
    {
      key: "file",
      label: <Form.Label>Audio File</Form.Label>,
      control: (
        <Form.Control
          type="file"
          // accept="audio/mpeg,audio/mp3"
          accept="audio/wav"
          onChange={onFileChanged}
        />
      ),
    },
  ];

  return (
    <CreateEditDeleteForm
      showDeleteButton={false}
      showSaveSpinner={createRes.isLoading || uploadRes.isLoading}
      showDeleteSpinner={false}
      canSave={isFormValid}
      onCancel={() => props.onClose()}
      onSave={() => onSaveClicked()}
      onDelete={() => null}
      controls={controls}
    />
  );
}
