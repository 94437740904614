import { cloneDeep } from "lodash";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useCreatePodcastMutation, useEditPodcastMutation, useDeletePodcastMutation } from "../api/apiSlice";
import { Serial } from "../api/interfaces";
import { CreateEditDeleteForm } from "../components/CreateEditDeleteForm";

interface PodcastFormProps {
  obj?: Serial;
  onClose: () => void;
}

export function PodcastForm(props: PodcastFormProps) {
  const [name, setName] = useState(props.obj ? props.obj.name : "");

  const [createPodcast, createRes] = useCreatePodcastMutation();
  const [editPodcast, editRes] = useEditPodcastMutation();
  const [deletePodcast, deleteRes] = useDeletePodcastMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNameChanged = (e: any) => setName(e.target.value);
  const isFormValid = [name].every(Boolean);

  async function onSaveClicked() {
    if (isFormValid) {
      try {
        if (props.obj) {
          // edit
          const copy: Serial = cloneDeep(props.obj);
          copy.name = name;
          await editPodcast(copy).unwrap();
        } else {
          // create
          await createPodcast({ name: name }).unwrap();
        }
      } catch (err) {
        console.error("Failed to save: ", err);
      }
      props.onClose();
    }
  }

  async function onDeleteClicked() {
    if (!props.obj) {
      return;
    }
    try {
      await deletePodcast(props.obj.id).unwrap();
    } catch (err) {
      console.error("Failed to save the Tag: ", err);
    }
    props.onClose();
  }

  const controls = [
    {
      key: "name",
      label: <Form.Label htmlFor="podcastName">Name:</Form.Label>,
      control: <Form.Control type="text" id="podcastName" name="podcastName" value={name} onChange={onNameChanged} />,
    },
  ];

  return (
    <CreateEditDeleteForm
      showDeleteButton={Boolean(props.obj)}
      showSaveSpinner={createRes.isLoading || editRes.isLoading}
      showDeleteSpinner={deleteRes.isLoading}
      canSave={isFormValid}
      onCancel={() => props.onClose()}
      onSave={() => onSaveClicked()}
      onDelete={() => onDeleteClicked()}
      controls={controls}
      deleteConfirmText="Deleting a Podcast will also delete its Episodes."
    />
  );
}
