import { cloneDeep } from "lodash";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import { SerialId } from "../api/interfaces";

interface SelectPodcastFormProps {
  podcasts: [SerialId, string][];
  initialSelect: SerialId | undefined;
  onPodSelected: (podId: SerialId, podName: string) => void;
}

export function SelectPodcastForm(props: SelectPodcastFormProps) {
  const initialIndex: number = props.podcasts.findIndex(
    (value: [SerialId, string], index: number, obj: [SerialId, string][]) => value[0] === props.initialSelect
  );
  console.log("SelectPodcastForm/Render");
  console.log("SelectPodcastForm/Render initialSelect: " + props.initialSelect);
  console.log("SelectPodcastForm/Render initialIndex: " + initialIndex);
  const notFound = -1;
  const [selectedPod, setSelectedPod] = useState<[SerialId, string] | undefined>(
    initialIndex === notFound ? undefined : cloneDeep(props.podcasts[initialIndex])
  );
  console.log("SelectPodcastForm/Render selectedPod: " + selectedPod);

  const onPodSelected = (id: string | null, e: unknown) => {
    // console.log(e);
    // console.log(eventKey);
    // TODO instead of text this should be by ID...
    if (id) {
      const tmp: [SerialId, string] | undefined = props.podcasts.find(
        (value: [SerialId, string], index: number, obj: [SerialId, string][]) => value[0].toString() === id
      );
      if (tmp) {
        const clone = cloneDeep(tmp);
        setSelectedPod(clone);
        props.onPodSelected(tmp[0], tmp[1]);
      }
    }
  };

  const getButtonLabel = function (prefix : string): string {
    // console.log(podcast);
    if (selectedPod) {
      return prefix + " - " + selectedPod[1];
    }
    return prefix;
  };

  const items = props.podcasts.map((pod: [SerialId, string], index: number) => (
    <Dropdown.Item key={pod[0]} eventKey={pod[0] as string}>
      {pod[1]}
    </Dropdown.Item>
  ));

  return (
    <React.Fragment>
      <Dropdown id="episodes-list-podcast-selector" onSelect={onPodSelected}>
        <Dropdown.Toggle variant="none" id="dropdown-basic">
          {getButtonLabel("Episodes")}
        </Dropdown.Toggle>
        <Dropdown.Menu>{items}</Dropdown.Menu>
      </Dropdown>

      {/* <DropdownButton variant="none" title={"Episodes - " + getButtonLabel()} onSelect={onPodSelected}>
        {items}
      </DropdownButton> */}
    </React.Fragment>
  );
}
