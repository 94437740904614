import React, { useEffect } from "react";
import { useGetInsertableAudioUrlQuery, useGetInsertsQuery } from "../features/api/apiSlice";
import { AudioAssetUrl, InsertableAudio } from "../features/api/interfaces";
import { ListPage } from "../features/components/ListPage";
import { InsertForm } from "../features/insertable_audio/InsertForm";
import AudioPlayer from "../features/components/AudioPlayer";

const REFETCH_INTERVAL_MS = 2000;

interface InsertListItemProps {
  obj: InsertableAudio;
}

function InsertListItemSummary(props: InsertListItemProps) {
  const audioUrlRes = useGetInsertableAudioUrlQuery(props.obj);

  // let's try a MediaStream object...
  // let audioSrcURL = getEnv("REACT_APP_BACKEND_API_URL") + "/" + props.obj.id + "/audio"
  // let mediaSource = new MediaSource()
  // let audioMediaURL = URL.createObjectURL(mediaSource);
  const audioMediaURL: AudioAssetUrl = { url: "", content_type: "" };
  if (audioUrlRes.isSuccess) {
    // audioMediaURL = URL.createObjectURL(audioUrlRes.data)
    audioMediaURL.url = audioUrlRes.data.url;
    audioMediaURL.content_type = audioUrlRes.data.content_type;
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!audioUrlRes.isSuccess) {
      timeout = setTimeout(() => {
        audioUrlRes.refetch();
      }, REFETCH_INTERVAL_MS);
    }
    return () => clearTimeout(timeout);
  }, [audioUrlRes]);

  return (
    <React.Fragment>
      <AudioPlayer audioURL={audioMediaURL.url} />
    </React.Fragment>
  );
}

function InsertListItemDetail(props: InsertListItemProps) {
  return (
    <React.Fragment>
      ID: {props.obj.id}
      <br />
      Name: {props.obj.name}
      {/* <br />
    Download: <a href={getEnv("REACT_APP_BACKEND_API_URL") + "insertable_audio/" + props.obj.id + "/audio"}>link</a> */}
    </React.Fragment>
  );
}

function Inserts() {
  const insertsRes = useGetInsertsQuery();

  if (insertsRes.isLoading) {
    //content = <Spinner text="Loading..." />
  } else if (insertsRes.isSuccess) {
    // content = res.data.map((tag: Tag) => <ListGroup.Item key={tag.id}>{tag.name}</ListGroup.Item>)
  } else if (insertsRes.isError && insertsRes.error) {
    // content = <div>{res.error.toString()}</div>
  }

  if (insertsRes.isSuccess) {
    return (
      <ListPage
        id="inserts"
        objName="Audio Insert"
        objNamePlural="Audio Inserts"
        objs={insertsRes.data}
        meta={{}}
        AddObjForm={InsertForm}
        EditObjForm={InsertForm}
        ObjSummaryComponent={InsertListItemSummary}
        ObjDetailComponent={InsertListItemDetail}
      />
    );
  }
  return <React.Fragment></React.Fragment>;
}

export default Inserts;
