import React, { FunctionComponent, useRef, useState } from "react";
import { Button, Row, Col, Spinner } from "react-bootstrap";
import { Duration } from "luxon";
import { Pause, Play } from "react-bootstrap-icons";
import { Slider } from "@mui/material";
import ReactPlayer from "react-player";
import { formatDuration } from "../../app/utils";

const MILLI_PER_SEC = 1000;

// granularity of slider, also used for rounding all seeking and displaying
const DFLT_SLIDER_STEP_MILLIS = 100;

// round milliseconds to slider step size
// (for example, if slider step size is 100 milliseconds,
// then round milliseconds to closest 100 (e.g. 1234 -> 1200)
function roundToSliderStep(value: Duration, step: number): Duration {
  const millis = value.as("milliseconds");
  const rounded = Math.round(millis / step) * step;
  return Duration.fromMillis(rounded);
}

// map markers from Duration to seconds, for
// use in the Slider component
function adaptMarkersToImplementationValues(
  markers: Array<{ label: React.ReactNode; value: Duration }>
): Array<{ label: React.ReactNode; value: number }> {
  return markers.map(
    (
      x: { label: React.ReactNode; value: Duration },
      index: number,
      array: { label: React.ReactNode; value: Duration }[]
    ) => {
      return { label: x.label, value: x.value.as("milliseconds") };
    }
  );
}

interface AudioPlayerProps {
  audioURL: string;
  valueLabelDisplay?: boolean;
  marks?: Array<{ label: React.ReactNode; value: Duration }> | boolean;
  onSliderChange?: (value: Duration) => void;
  // TODO fix slider precision for values other than 100
  sliderPrecision?: number;
}

const AudioPlayer: FunctionComponent<AudioPlayerProps> = (
  props: AudioPlayerProps = {
    audioURL: "",
    valueLabelDisplay: false,
    marks: false,
    sliderPrecision: DFLT_SLIDER_STEP_MILLIS,
  }
) => {
  // console.log("AudioPlayer/render: props: " + JSON.stringify(props));

  const sliderStepMillis = props.sliderPrecision ? props.sliderPrecision : DFLT_SLIDER_STEP_MILLIS;

  const [, /* isReady */ setReady] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [, /* isError */ setError] = useState(false);
  const playerInstance = useRef<ReactPlayer>(null);

  // wrap setters in roundToSliderStep(), so that everywhere else
  // we can just call setters without having to worry about rounding
  // or accuracy
  const [playedMilli, setPlayedMilliImpl] = useState(Duration.fromMillis(0));
  const setPlayedMilli = (d: Duration) => setPlayedMilliImpl(roundToSliderStep(d, sliderStepMillis));
  const [durationMilli, setDurationMilliImpl] = useState(Duration.fromMillis(0));
  const setDurationMilli = (d: Duration) => setDurationMilliImpl(roundToSliderStep(d, sliderStepMillis));

  function onPlayPause() {
    setPlaying(!isPlaying);
  }

  function onEnded() {
    console.log("AudioPlayer/onEnded");
    setPlaying(false);
  }

  function onReady() {
    console.log("AudioPlayer/onReady");
    setReady(true);
  }

  function onDuration(seconds: number) {
    const duration = Duration.fromMillis(seconds * MILLI_PER_SEC);
    console.log("AudioPlayer/onDuration: " + duration.toString());
    setDurationMilli(duration);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onError(error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) {
    console.log("AudioPlayer/onError: error: " + JSON.stringify(error));
    console.log("AudioPlayer/onError: data: " + JSON.stringify(data));
    setReady(false);
    setError(true);
  }

  function onProgress(state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) {
    // console.log("AudioPlayer/onProgress: " + JSON.stringify(state));
    const tmp = Duration.fromMillis(state.playedSeconds * MILLI_PER_SEC);
    const played = roundToSliderStep(tmp, sliderStepMillis);
    setPlayedMilli(played);
    if (props.onSliderChange) {
      props.onSliderChange(played);
    }
  }

  // User input changes the slider, triggers onSliderSeek.
  // onSliderSeek invokes seeking the player, which triggers
  // onProgress, which updates playedMilli and fires props' callbacks
  function onSliderSeek(event: Event, value: number | Array<number>, activeThumb: number): void {
    if (typeof value === "number" && playerInstance.current !== null) {
      const seconds = Math.round(value) / MILLI_PER_SEC;
      playerInstance.current.seekTo(seconds, "seconds");
    }
  }

  let playPauseButton = <React.Fragment />;
  const disableControls = props.audioURL.length === 0;
  if (disableControls) {
    playPauseButton = (
      <Button disabled={disableControls}>
        <Spinner animation="border" variant="secondary" size="sm" />
      </Button>
    );
  } else if (isPlaying) {
    playPauseButton = (
      <Button onClick={onPlayPause}>
        <Pause />
      </Button>
    );
  } else {
    playPauseButton = (
      <Button onClick={onPlayPause}>
        <Play />
      </Button>
    );
  }

  let marks: Array<{ label: React.ReactNode; value: number }> | boolean;
  if (typeof props.marks === "boolean") {
    marks = props.marks;
  } else if (props.marks === undefined) {
    marks = false;
  } else {
    marks = adaptMarkersToImplementationValues(props.marks);
  }

  return (
    <React.Fragment>
      <Row className="audio-player">
        <Col xs="auto">{playPauseButton}</Col>
        <Col>
          <Row>
            <Col xs="auto">{formatDuration(playedMilli, sliderStepMillis)}</Col>
            <Col></Col>
            <Col xs="auto">{"-" + formatDuration(durationMilli.minus(playedMilli), sliderStepMillis)}</Col>
          </Row>
          <Row>
            <Col>
              <Slider
                defaultValue={0}
                value={playedMilli.as("milliseconds")}
                step={props.sliderPrecision}
                valueLabelDisplay={props.valueLabelDisplay ? "on" : "off"}
                valueLabelFormat={(value: number, index: number) =>
                  formatDuration(roundToSliderStep(Duration.fromMillis(value), sliderStepMillis), sliderStepMillis)
                }
                marks={marks}
                min={0}
                max={durationMilli.as("milliseconds")}
                onChange={onSliderSeek}
                disabled={disableControls}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <ReactPlayer
        ref={playerInstance}
        url={props.audioURL}
        playing={isPlaying}
        width={0}
        height={0}
        progressInterval={sliderStepMillis}
        onReady={onReady}
        // onStart={() => console.log("AudioPlayer/onStart")}
        // onPlay={() => console.log("AudioPlayer/onPlay")}
        // onPause={() => console.log("AudioPlayer/onPause")}
        // onBuffer={() => console.log("AudioPlayer/onBuffer")}
        // onBufferEnd={() => console.log("AudioPlayer/onBufferEnd")}
        // onSeek={onPlayerSeek} // not needed, feedback loop is via onProgress
        onProgress={onProgress}
        onDuration={onDuration}
        onEnded={onEnded}
        onError={onError}
        playsinline={true} // iOS requires player to be fullscreen unless this is set
        config={{
          file: {
            forceAudio: true,
            // attributes: {"crossOrigin": "use-credentials"}
          },
        }}
      />
    </React.Fragment>
  );
};

export default AudioPlayer;
