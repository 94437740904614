import React from "react";
import ReactDOM from "react-dom";
import "./custom.scss";
import Home from "./Home";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Auth0ContextInterface, Auth0Provider, GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import { getEnv } from "./app/utils";

// expose a static getAccessToken so that it can be used in apiSlice (outside of a Component)
// since normally getAccessTokenSilently is only available within the Components
// nested in an Auth0Provider
// https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
// https://github.com/auth0/auth0-react/issues/67#issuecomment-919217348
type GetAccessTokenSilentlyFcn = Auth0ContextInterface["getAccessTokenSilently"];

type DeferredT = {
  promise: Promise<GetAccessTokenSilentlyFcn>;
  resolve: (value: GetAccessTokenSilentlyFcn) => void;
};

const deferred: DeferredT = (() => {
  // create with dummy values
  const obj: DeferredT = {
    promise: new Promise((resolve: (value: GetAccessTokenSilentlyFcn) => void, reject: (reason?: unknown) => void) => {
      return;
    }),
    resolve: (value: GetAccessTokenSilentlyFcn) => {
      return;
    },
  };

  obj.promise = new Promise(
    (resolve: (value: GetAccessTokenSilentlyFcn) => void, reject: (reason?: unknown) => void) => {
      obj.resolve = resolve;
    }
  );
  return obj;
})();

export async function getAccessToken(
  options: GetTokenSilentlyOptions & { detailedResponse: true }
): Promise<GetTokenSilentlyVerboseResponse>;
export async function getAccessToken(options?: GetTokenSilentlyOptions): Promise<string>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAccessToken(options: any): Promise<GetTokenSilentlyVerboseResponse | string> {
  const getToken = await deferred.promise;
  return getToken(options);
}

// helper Component that calls useAuth0() and exposes
// getAccessTokenSilently globally, for later use by
// out-of-React-Context functions (i.e. RTK Query stuff)
function InterceptAuth0Context(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  deferred.resolve(getAccessTokenSilently);
  return <Home />;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={getEnv("REACT_APP_AUTH0_DOMAIN")}
        clientId={getEnv("REACT_APP_AUTH0_CLIENT_ID")}
        redirectUri={window.location.origin + getEnv("REACT_APP_AUTH0_REDIRECT_PATH")}
        audience={getEnv("REACT_APP_AUTH0_AUDIENCE")}
        scope={getEnv("REACT_APP_AUTH0_SCOPE")}
      >
        <InterceptAuth0Context />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
