import React, { ComponentType, useState } from "react";
import { Button, Col, Container, Row, OverlayTrigger, Tooltip, ListGroup, Card } from "react-bootstrap";
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AddObjFormProps<T extends ItemBaseProps, Meta> {
  meta: Meta;
  onClose: () => void;
}

interface EditObjFormProps<T extends ItemBaseProps, Meta> {
  obj: T;
  meta: Meta;
  onClose: () => void;
}

interface ObjDisplayProps<T extends ItemBaseProps, Meta> {
  obj: T;
  meta: Meta;
}

export interface ItemBaseProps {
  id: string;
  name: string;
}

interface ListPageProps<T extends ItemBaseProps, Meta> {
  id: string;
  objName: string;
  objNamePlural: string;
  objs: T[];
  meta: Meta;
  // https://www.developerway.com/posts/react-component-as-prop-the-right-way
  HeaderComp?: JSX.Element;
  AddObjForm: ComponentType<AddObjFormProps<T, Meta>>;
  EditObjForm: ComponentType<EditObjFormProps<T, Meta>>;
  ObjSummaryComponent: ComponentType<ObjDisplayProps<T, Meta>>;
  ObjDetailComponent: ComponentType<ObjDisplayProps<T, Meta>>;

  // TODO isloading, iserror?
}

interface ListItemProps<T extends ItemBaseProps, Meta> {
  id: string;
  obj: T;
  meta: Meta;
  EditObjForm: ComponentType<EditObjFormProps<T, Meta>>;
  ObjSummaryComponent: ComponentType<ObjDisplayProps<T, Meta>>;
  ObjDetailComponent: ComponentType<ObjDisplayProps<T, Meta>>;
}

function ListItem<T extends ItemBaseProps, Meta>(props: ListItemProps<T, Meta>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <props.EditObjForm obj={props.obj} meta={props.meta} onClose={() => setIsEditing(false)} />
          </Col>
        </Row>
      </React.Fragment>
    );
  } else {
    // !isEditing
    let details = null;
    const editButton = (
      <Button onClick={() => setIsEditing(true)}>
        <PencilSquare /> Edit
      </Button>
    );
    if (isOpen) {
      details = (
        <Row>
          <Col xs="1">{/* placeholder for alignment */}</Col>
          <Col xs="11">
            <props.ObjDetailComponent obj={props.obj} meta={props.meta} />
          </Col>
        </Row>
      );
    }
    return (
      <React.Fragment>
        <Row>
          <Col xs="auto" md="3">
            <Button variant="link" className="list-page-obj-summay-link-btn text-start" onClick={() => setIsOpen(!isOpen)}>
              {props.obj.name}
            </Button>
          </Col>
          <Col>
            <props.ObjSummaryComponent obj={props.obj} meta={props.meta} />
          </Col>
          <Col xs="auto">{editButton}</Col>
        </Row>
        {details}
      </React.Fragment>
    );
  }
}

export function ListPage<T extends ItemBaseProps, Meta>(props: ListPageProps<T, Meta>) {
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  function onAddFormClose(): void {
    setIsAddFormOpen(false);
  }

  let openAddFormButton = <React.Fragment />;
  let addFormRow = null;

  if (!isAddFormOpen) {
    openAddFormButton = (
      <OverlayTrigger
        key={props.id + "-add-button"}
        placement="bottom"
        overlay={<Tooltip>Add a {props.objName}</Tooltip>}
      >
        <Button variant="secondary" disabled={isAddFormOpen} onClick={() => setIsAddFormOpen(true)}>
          <PlusSquare /> New {props.objName}
        </Button>
      </OverlayTrigger>
    );
  } else {
    addFormRow = (
      <React.Fragment>
        <Row className="g-3">
          <Col>
            <Card>
              <Card.Header>Create New {props.objName}</Card.Header>
              <Card.Body>
                <props.AddObjForm meta={props.meta} onClose={onAddFormClose} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="spacer-row"></Row>
      </React.Fragment>
    );
  }

  const itemRows = props.objs.map((x: T) => (
    <ListGroup.Item key={x.id} className="pe-0 ps-3">
      <ListItem<T, Meta>
        id={x.id}
        obj={x}
        meta={props.meta}
        ObjSummaryComponent={props.ObjSummaryComponent}
        ObjDetailComponent={props.ObjDetailComponent}
        EditObjForm={props.EditObjForm}
      />
    </ListGroup.Item>
  ));

  let header = <div className="list-page-header">{props.objNamePlural}</div>;
  if (props.HeaderComp) {
    header = props.HeaderComp;
  }

  return (
    <Container id={props.id + "-list-page"} className="my-5">
      <Row className="my-4">
        <Col>{header}</Col>
        <Col xs="auto">{openAddFormButton}</Col>
      </Row>
      {addFormRow}
      <ListGroup variant="flush">{itemRows}</ListGroup>
    </Container>
  );
}
