import React, { useEffect, useState } from "react";
import { Episode, MarkerId, Marker, TagId, Tag, AudioAssetUrl } from "../api/interfaces";
import { Button, Row, Col, Dropdown, DropdownButton, Form, Spinner, Modal } from "react-bootstrap";
import { Card, Chip, Typography } from "@mui/material";
import { PlusSquare, Trash } from "react-bootstrap-icons";
import { Duration } from "luxon";
import { cloneDeep, uniqueId } from "lodash";
import {
  useCreateMarkerMutation,
  useDeleteEpisodeMutation,
  useDeleteMarkerMutation,
  useEditEpisodeMutation,
  useEditMarkerMutation,
  useGetEpisodeRawAudioUrlQuery,
} from "../api/apiSlice";
import AudioPlayer from "../components/AudioPlayer";
import { formatDuration } from "../../app/utils";

const REFETCH_INTERVAL_MS = 2000;

// granularity of slider, also used for rounding all seeking and displaying
const SLIDER_STEP_MILLIS = 100;

export interface EpisodeEditorInputObj {
  id: string;
  name: string;
  episode: Episode;
  markers: Marker[]; // markers for this episode
}

interface EpisodeEditorProps {
  obj: EpisodeEditorInputObj;
  meta: { tags: Tag[] }; // all tags (incl other episodes and markers)
  onClose: () => void;
}

function DraftMarkerLabel(props: { timestamp: Duration; tag: Tag | undefined }) {
  return (
    <Card>
      <Typography>{formatDuration(props.timestamp, SLIDER_STEP_MILLIS)}</Typography>
      <Chip label={props.tag ? props.tag.name : "<not selected>"} />
    </Card>
  );
}

interface DraftMarker {
  value: Duration;
  tag: Tag | undefined;
  id: MarkerId | undefined;
  key: string; // for ordering in lists and identifying marker in callbacks (might be different than ID)
  dirty: boolean;
  deleted: boolean;
}

function getInitialDraftMarkers(props: EpisodeEditorProps) {
  const rval: DraftMarker[] = [];
  props.obj.markers.forEach((m: Marker, index: number, array: Marker[]) => {
    // TODO optimize search
    rval.push({
      value: Duration.fromMillis(m.timestamp),
      tag: props.meta.tags.find((x: Tag, i: number, arr: Tag[]) => x.id === m.tag_id),
      id: m.id,
      key: m.id, // use crypto.randomUUID() for DraftMarkers that are not yet saved
      dirty: false,
      deleted: false,
    });
  });
  return rval;
}

export function EpisodeEditor(props: EpisodeEditorProps) {
  const [draftMarkers, setDraftMarkers] = useState<DraftMarker[]>(() => getInitialDraftMarkers(props));
  const [currTime, setCurrTime] = useState<Duration>(Duration.fromMillis(0));
  const [name, setName] = useState<string>(props.obj.episode.name);
  const [showSaveSpinner, setShowSaveSpinner] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const disableControls = showDeleteSpinner || showSaveSpinner;

  const [createMarker] = useCreateMarkerMutation();
  const [editMarker] = useEditMarkerMutation();
  const [deleteMarker] = useDeleteMarkerMutation();
  const [editEpisode] = useEditEpisodeMutation();
  const [deleteEpisode] = useDeleteEpisodeMutation();

  const audioUrlRes = useGetEpisodeRawAudioUrlQuery(props.obj.episode);

  const sliderMarks: Array<{ label: React.ReactNode; value: Duration }> = [];
  draftMarkers.sort((a, b) => a.value.valueOf() - b.value.valueOf());
  draftMarkers.forEach((x: DraftMarker, index: number, array: DraftMarker[]) => {
    if (x.deleted) {
      return;
    }
    sliderMarks.push({ value: x.value, label: <DraftMarkerLabel timestamp={x.value} tag={x.tag} /> });
  });

  function onClickMarkerAdd(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void {
    const newDraftMarkers = cloneDeep(draftMarkers);
    newDraftMarkers.push({
      value: Duration.fromDurationLike(currTime),
      tag: undefined,
      id: undefined,
      key: uniqueId("draft_marker_"),
      dirty: true,
      deleted: false,
    });
    setDraftMarkers(newDraftMarkers);
  }

  function onSliderChange(value: Duration): void {
    setCurrTime(value);
  }

  function onSelectDraftMakerTag(eventKey: string | null, draftMarkerKey: string): void {
    if (!eventKey) {
      return;
    }
    const tagID = eventKey as TagId;
    const newDraftMarkers = cloneDeep(draftMarkers);
    // TODO get Tags[] as both array and map w/ index on IDs
    const obj = newDraftMarkers.find((x: DraftMarker, index: number, arr: DraftMarker[]) => x.key === draftMarkerKey);
    const tag = props.meta.tags.find((x: Tag, index: number, arr: Tag[]) => x.id === tagID);
    if (obj && tag) {
      obj.tag = tag;
      obj.dirty = true;
    }
    setDraftMarkers(newDraftMarkers);
  }

  function deleteDraftMarker(draftMarkerKey: string): void {
    const newDraftMarkers = cloneDeep(draftMarkers);
    const obj = newDraftMarkers.find((x: DraftMarker, index: number, arr: DraftMarker[]) => x.key === draftMarkerKey);
    if (obj) {
      obj.deleted = true;
    }
    setDraftMarkers(newDraftMarkers);
  }

  async function onClickEpisodeSave(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    if (!isValidSave()) {
      return;
    }
    // update or delete all Markers
    draftMarkers.forEach((x: DraftMarker, index: number, arr: DraftMarker[]) => {
      if (x.id === undefined) {
        // TODO unify validation
        if (x.tag !== undefined) {
          createMarker({
            timestamp: x.value.toMillis(),
            episode_id: props.obj.episode.id,
            tag_id: x.tag.id,
          });
        }
      } else if (x.deleted && x.id) {
        deleteMarker(x.id);
      } else if (x.dirty && x.tag) {
        // we already know x.tag is defined from prior isValidSave() check
        const copy: Marker = {
          id: x.id,
          episode_id: props.obj.episode.id,
          timestamp: x.value.toMillis(),
          tag_id: x.tag.id,
        };
        editMarker(copy);
      }
    });
    // update the Episode
    try {
      // edit
      const copy: Episode = cloneDeep(props.obj.episode);
      copy.name = name;
      await editEpisode(copy).unwrap();
    } catch (err) {
      console.error("Failed to save: ", err);
    }
    props.onClose();
  }

  async function onConfirmEpisodeDelete(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    try {
      await deleteEpisode(props.obj.episode.id);
    } catch (err) {
      console.error("Failed to save the Tag: ", err);
    }
    props.onClose();
  }

  function isValidSave(): boolean {
    const found_invalid = draftMarkers.find(
      (x: DraftMarker, index: number, arr: DraftMarker[]) => x.tag === undefined && !x.deleted
    );
    return [name, found_invalid === undefined].every(Boolean);
  }

  function onClickCancel(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void {
    props.onClose();
  }

  const tags: JSX.Element[] = props.meta.tags.map((tag: Tag, index: number) => (
    <Dropdown.Item key={tag.id} eventKey={tag.id as string}>
      {tag.name}
    </Dropdown.Item>
  ));

  const markerRows = draftMarkers
    .filter((x: DraftMarker, index: number, array: DraftMarker[]) => !x.deleted)
    .map((x: DraftMarker, index: number, array: DraftMarker[]) => {
      return (
        <Row key={x.key}>
          <Col xs="4" md="2">
            {formatDuration(x.value, SLIDER_STEP_MILLIS)}
          </Col>
          <Col xs="4" md="2">
            <DropdownButton
              id={"episode-editor-tag-selector-" + x.key}
              title={x.tag ? x.tag.name : "Select Tag"}
              onSelect={(eventKey: string | null, event: unknown) => onSelectDraftMakerTag(eventKey, x.key)}
              className="episode-editor-tag-dropdown"
            >
              {tags}
            </DropdownButton>
          </Col>
          <Col></Col>
          <Col xs="auto">
            <Button variant="danger" onClick={(event: unknown) => deleteDraftMarker(x.key)}>
              <Trash />
            </Button>
          </Col>
        </Row>
      );
    });

  const markerTable = (
    <React.Fragment>
      <Row>
        <Col xs="4" md="2" className="episode-editor-marker-table-header">
          Timestamp
        </Col>
        <Col xs="4" md="2" className="episode-editor-marker-table-header">
          Tag
        </Col>
        <Col className="episode-editor-marker-table-header"></Col>
        <Col xs="auto" className="episode-editor-marker-table-header"></Col>
      </Row>
      {markerRows}
    </React.Fragment>
  );

  const audioMediaURL: AudioAssetUrl = { url: "", content_type: "" };
  if (audioUrlRes.isSuccess) {
    // audioMediaURL = URL.createObjectURL(audioUrlRes.data)
    audioMediaURL.url = audioUrlRes.data.url;
    audioMediaURL.content_type = audioUrlRes.data.content_type;
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!audioUrlRes.isSuccess) {
      timeout = setTimeout(() => {
        audioUrlRes.refetch();
      }, REFETCH_INTERVAL_MS);
    }
    return () => clearTimeout(timeout);
  }, [audioUrlRes]);

  const episodeFieldControls = (
    <Row className="g-3">
      <Col xs="auto">
        <Form.Label htmlFor="episodeName">Name:</Form.Label>
      </Col>
      <Col>
        <Form.Control
          type="text"
          id="episodeName"
          name="episodeName"
          value={name}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => setName(e.target.value)}
        />
      </Col>
    </Row>
  );

  const bottomButtons = (
    <Row className="mt-3">
      <Col xs="auto">
        {/* TODO delete confirmation modal */}
        <Button
          variant="danger"
          type="button"
          onClick={() => setShowDeleteConfirmModal(true)}
          disabled={disableControls}
        >
          {showDeleteSpinner ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Delete
            </div>
          ) : (
            "Delete"
          )}
        </Button>
      </Col>
      <Col></Col>
      <Col xs="auto">
        <Button variant="secondary" type="button" onClick={onClickCancel} disabled={disableControls}>
          Cancel
        </Button>
      </Col>
      <Col xs="auto">
        <Button
          variant="primary"
          type="button"
          onClick={onClickEpisodeSave}
          disabled={disableControls || !isValidSave()}
        >
          {showSaveSpinner ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Save
            </div>
          ) : (
            "Save"
          )}
        </Button>
      </Col>
    </Row>
  );

  let modal = <React.Fragment></React.Fragment>;
  if (showDeleteConfirmModal) {
    modal = (
      <React.Fragment>
        <Modal
          show={showDeleteConfirmModal}
          onHide={() => setShowDeleteConfirmModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onConfirmEpisodeDelete}>
              Yes, Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <div className="episode-editor">
      <Form>
        <Row>
          <Col>
            <h3>{name}</h3>
          </Col>
        </Row>
        {episodeFieldControls}
        <Row className="my-3"></Row>
        <AudioPlayer
          audioURL={audioMediaURL.url}
          valueLabelDisplay={true}
          marks={sliderMarks}
          onSliderChange={onSliderChange}
          sliderPrecision={SLIDER_STEP_MILLIS}
        />
        <Row className="my-4"></Row>
        <Row>
          <Col>
            <h4>Current Inserts</h4>
          </Col>
          <Col xs="auto">
            <Button onClick={onClickMarkerAdd}>
              <PlusSquare /> New Insert at {formatDuration(currTime, SLIDER_STEP_MILLIS)}
            </Button>
          </Col>
        </Row>
        {markerTable}
        <Row className="my-3"></Row>
        {bottomButtons}
      </Form>
      {modal}
    </div>
  );
}
