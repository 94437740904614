import React, { useState } from "react";
import { Button, Col, Row, Form, Spinner, Modal } from "react-bootstrap";

interface FormRow {
  key: string;
  label: JSX.Element;
  control: JSX.Element;
}

interface CreateEditDeleteFormProps {
  showDeleteButton: boolean;
  showSaveSpinner: boolean;
  showDeleteSpinner: boolean;

  canSave: boolean; // i.e. is the form valid

  onCancel: () => void;
  onSave: () => void;
  onDelete: () => void;

  controls: Array<FormRow>;

  deleteConfirmText?: string;
}

export function CreateEditDeleteForm(props: CreateEditDeleteFormProps) {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const anyLoading = [props.showSaveSpinner, props.showDeleteSpinner].some(Boolean);

  const disableControls = anyLoading;
  const canSave = !anyLoading && props.canSave;

  function onSaveClicked() {
    if (canSave) {
      props.onSave();
    } else {
      console.error("Cannot to save; canSave === false ");
    }
  }

  function onCancelClicked(): void {
    props.onCancel();
  }

  function onConfirmDeleteClicked() {
    props.onDelete();
  }

  let deleteButton = <React.Fragment />;

  if (props.showDeleteButton) {
    deleteButton = (
      <Col xs="auto">
        <Button
          variant="danger"
          type="button"
          onClick={() => setShowDeleteConfirmModal(true)}
          disabled={disableControls}
        >
          {props.showDeleteSpinner ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Delete
            </div>
          ) : (
            "Delete"
          )}
        </Button>
      </Col>
    );
  }
  const bottomButtons = (
    <Row className="mt-3">
      {deleteButton}
      <Col></Col>
      <Col xs="auto">
        <Button variant="secondary" type="button" onClick={onCancelClicked} disabled={disableControls}>
          Cancel
        </Button>
      </Col>
      <Col xs="auto">
        <Button variant="primary" type="button" onClick={onSaveClicked} disabled={disableControls || !canSave}>
          {props.showSaveSpinner ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Save
            </div>
          ) : (
            "Save"
          )}
        </Button>
      </Col>
    </Row>
  );

  const controls = props.controls.map((x: FormRow) => (
    <Row key={x.key} className="g-3">
      <Col xs="3" md="1">
        {x.label}
      </Col>
      <Col>{x.control}</Col>
    </Row>
  ));

  let modal = <React.Fragment></React.Fragment>;

  if (showDeleteConfirmModal) {
    let deleteConfirmText = "Are you sure you want to delete?"
    if (props.deleteConfirmText) {
      deleteConfirmText += " " + props.deleteConfirmText
    }
    modal = (
      <React.Fragment>
        <Modal
          show={showDeleteConfirmModal}
          onHide={() => setShowDeleteConfirmModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>{deleteConfirmText}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onConfirmDeleteClicked}>
              Yes, Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Form>
        {controls}
        {bottomButtons}
        {modal}
      </Form>
    </React.Fragment>
  );
}
